import React from 'react';

const Post = () => {
    return (
        <div>
            <h1>hello world</h1>
        </div>
    );
}

export default Post;
