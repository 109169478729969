import React from 'react';
import './home.style.css'

const Home = () => {
    return (
        <div>
            <h1>Home page</h1>
        </div>
    );
}

export default Home;
